import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AppTypeSelector } from '../elements/AppTypeSelector';
import { Icon, IconType } from '../elements/Icon';
import { WithTestID } from '../elements/WithTestID';

export interface NavLinkProps extends WithTestID {
    icon: IconType;
    label: string;
    link: string;
    access?: boolean;
}

const NavLink: React.VFC<NavLinkProps> = ({icon, label, link, access = true, testID}) => {
    if (!access) return null;
    return (
        <Nav.Link data-testid={`${testID}:${label.toLowerCase()}`} to={link} as={Link}>
            <Icon icon={icon} className="mx-1" />
            {label}
        </Nav.Link>
    );
};

interface DropdownItemDividerProps {
    type: 'divider';
}

interface DropdownItemCallbackProps {
    type: 'callback';
    label: string;
    icon: IconType;
    onClick: () => void;
    access?: boolean;
}

interface DropdownItemLinkProps {
    type: 'link';
    label: string;
    icon: IconType;
    link: string;
    access?: boolean;
}

export type DropdownItemProps = DropdownItemDividerProps | DropdownItemCallbackProps | DropdownItemLinkProps;

const DropdownItem: React.VFC<DropdownItemProps> = (props) => {
    if (props.type === 'divider') {
        return <NavDropdown.Divider />;
    }

    if (props.type === 'callback') {
        const { onClick, access, icon, label } = props;
        if (access === false) return null;

        return (
            <NavDropdown.Item onClick={onClick}>
                <span className="mx-1 d-inline-block text-center" style={{width: 20}}>
                    <Icon icon={icon} />
                </span>
                {label}
            </NavDropdown.Item>
        );
    }

    if (props.type === 'link') {
        const { link, access, icon, label } = props;
        if (access === false) return null;

        return (
            <NavDropdown.Item as={Link} to={link}>
                <span className="mx-1 d-inline-block text-center" style={{width: 20}}>
                    <Icon icon={icon} />
                </span>
                {label}
            </NavDropdown.Item>
        );
    }

    return null;
};

export interface NavLayoutProps extends WithTestID {
    links?: NavLinkProps[];
    dropDownTitle: string;
    dropDownItems?: DropdownItemProps[];
    logoutOnly?: boolean;
}

export const NavLayout: React.VFC<NavLayoutProps> = ({ links = [], dropDownTitle, dropDownItems = [], logoutOnly = false, testID }) => {
    return (
        <Navbar className="app-nav fixed-top bg-white border-bottom" data-testid={testID}>
            <Container>

                {!logoutOnly && <AppTypeSelector />}

                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        {links.map(opt => <NavLink testID={testID} key={opt.link} {...opt} />)}

                        <NavDropdown title={dropDownTitle} data-testid={testID ? `${testID}:menu` : undefined}>
                            {dropDownItems.map((opt, idx) => <DropdownItem key={`opt_${idx}`} {...opt} />)}
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};
