import React from 'react';
import {Toast as BSToast, ToastProps as BSToastProps} from 'react-bootstrap';

import { assertNever } from '../../../services/TypeUtils';
import { Icon, IconType } from './Icon';

import './AutoHideToast.scss';

export enum ToastVariant {
    Primary = 'Primary',
    Secondary = 'Secondary',
    Warning = 'Warning',
    Danger = 'Danger',
    Success = 'Success',
    Default = 'Default',
}

export interface AutoHideToastProps {
    show: boolean;
    onHide: () => void;
    header: React.ReactNode;
    variant?: ToastVariant;
    icon?: IconType;
}

const convertVariantToBootstrapVariant = (variant: ToastVariant): BSToastProps['bg'] => {
    switch (variant) {
        case ToastVariant.Primary: return 'primary';
        case ToastVariant.Secondary: return 'secondary';
        case ToastVariant.Warning: return 'warning';
        case ToastVariant.Danger: return 'danger';
        case ToastVariant.Success: return 'success';
        case ToastVariant.Default: return '';
        default: return assertNever(variant);
    }
};

const AutoHideDelay = 5000;

export const AutoHideToast: React.FC<AutoHideToastProps> = ({ show, onHide, header, variant = ToastVariant.Default, icon, children }) => {

    return (
        <BSToast onClose={onHide} show={show} delay={AutoHideDelay} autohide bg={convertVariantToBootstrapVariant(variant)}>
            <BSToast.Header className="gap-2">
                { icon && <Icon icon={icon} /> }
                <span className="me-auto">{header}</span>
            </BSToast.Header>
            <BSToast.Body>{children}</BSToast.Body>
        </BSToast>
    );

};
