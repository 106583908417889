import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import { TextField } from '../../components/form/fields/TextField';
import { Form } from '../../components/form/forms/Form';
import { Alert, AlertVariant } from '../../components/ui/elements/Alert';
import { ButtonVariant, SubmitButton } from '../../components/ui/elements/Button';
import { LoadingOverlay } from '../../components/ui/elements/LoadingOverlay';
import { ButtonList } from '../../components/ui/layouts/ButtonList';
import { CenterSmallContainerWithSupportBanner } from '../../components/ui/layouts/CenterSmallContainerWithSupportBanner';
import { useExternalResource } from '../../Context/ServiceContext';
import { submitPasswordReset } from '../../services/submitPasswordReset';
import { WithTestID } from '../../components/ui/elements/WithTestID';

enum PageState {
    Initial = 'Initial',
    Success = 'Success',
    Error = 'Error',
}

export const ResetSubmit: React.VFC<WithTestID> = ({ testID }) => {
    const key = useKeyQueryParam();
    const [pageState, setPageState] = useState(PageState.Initial);

    if (!key) {
        return (
            <CenterSmallContainerWithSupportBanner>
                <h1 data-testid={testID ? `${testID}:key-error-header` : undefined}>Password Reset</h1>
                <div className="py-2">
                    <Alert variant={AlertVariant.ERROR}>
                        Invalid password reset link. Please contact your system administrator for help.
                    </Alert>
                </div>
            </CenterSmallContainerWithSupportBanner>
        );
    }

    return (
        <CenterSmallContainerWithSupportBanner>
            <h1 data-testid={testID ? `${testID}:header` : undefined}>Password Reset</h1>

            {pageState === PageState.Initial && (
                <ResetForm
                    resetKey={key}
                    onSuccess={() => setPageState(PageState.Success)}
                    onError={() => setPageState(PageState.Error)}
                    testID={testID ? `${testID}:reset-form` : undefined}
                />
            )}
            {pageState === PageState.Success && <ResetSuccess testID={testID ? `${testID}:success` : undefined} />}
            {pageState === PageState.Error && <ResetError testID={testID ? `${testID}:error` : undefined} />}
        </CenterSmallContainerWithSupportBanner>
    );

};

interface ResetFormValues {
    password: string;
    confirmPassword: string;
}

const initialValues: ResetFormValues = {
    password: '',
    confirmPassword: '',
}

const useKeyQueryParam = () => {
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    return params.get('key');
};

interface ResetFormProps extends WithTestID {
    onSuccess: () => void;
    onError: () => void;
    resetKey: string;
}
const ResetForm: React.VFC<ResetFormProps> = ({ onSuccess, onError, resetKey, testID }) => {

    const externalResource = useExternalResource();
    const [submitting, setSubmitting] = useState(false);

    const submit = async (values: ResetFormValues) => {
        try {
            setSubmitting(true);
            await submitPasswordReset(externalResource, resetKey, values.password);
            setSubmitting(false);
            onSuccess();
        } catch (err) {
            setSubmitting(false);
            onError();
        }
    };

    return (
        <>
            <div className="py-2">
                <Form
                    testID={testID}
                    FormBody={() => (
                        <>
                            <TextField
                                type="password"
                                id="password"
                                label="New password"
                                placeholder="Enter new password"
                                required
                                testID={testID ? `${testID}:password` : undefined}
                            />
                            <TextField
                                type="password"
                                id="confirmPassword"
                                label="Confirm password"
                                placeholder="Enter new password"
                                required
                                testID={testID ? `${testID}:confirm-password` : undefined}
                            />
                        </>
                    )}
                    onSubmit={submit}
                    initialValues={initialValues}
                    FormActions={({ isSubmitting, values }) => {
                        const isValid = !!values.password && values.password === values.confirmPassword;
                        return (
                          <ButtonList>
                              <SubmitButton
                                variant={ButtonVariant.PRIMARY}
                                disabled={isSubmitting || !isValid}
                                testID={testID ? `${testID}:submit` : undefined}
                            >Reset</SubmitButton>
                          </ButtonList>
                        );
                    }}
                    validate={(values => {
                        let errors: Record<string, string> = {};
                        if (values.password === '') {
                            errors.password = 'Password is required';
                        } else if (values.password.length < 5) {
                            errors.password = 'Password is required to be at least 5 characters';
                        } else if (values.password.length > 50) {
                            errors.password = 'Password cannot be longer than 50 characters';
                        }

                        if (values.confirmPassword === '') {
                            errors.confirmPassword = 'Confirm password is required';
                        } else if (values.password !== values.confirmPassword) {
                            errors.confirmPassword = 'Passwords do not match';
                        }

                        return errors;
                    })}
                />
            </div>
            <LoadingOverlay show={submitting} message="Please wait..." />
        </>
    );

};

const ResetSuccess: React.VFC<WithTestID> = ({ testID }) => {
    return (
        <>
            <div className="py-2" data-testid={testID}>
                <p>Your password has been reset.</p>
            </div>
            <div className="py-2">
                <Link to="/login" className="btn btn-primary">Login</Link>
            </div>
        </>
    );
};

const ResetError: React.VFC<WithTestID> = ({ testID }) => {
    return (
        <>
            <div className="py-2" data-testid={testID}>
                <Alert variant={AlertVariant.ERROR}>
                    Your password couldn't be reset. Remember a password request is only valid for 24 hours.
                </Alert>
            </div>
        </>
    );
};
