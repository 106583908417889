import React from 'react';
import { AutoHideToastProps, ToastVariant } from '../components/ui/elements/AutoHideToast';
import { IconType } from '../components/ui/elements/Icon';
import { Log } from '../log/log';

type PartialToastProps = Pick<AutoHideToastProps, 'header'|'icon'|'variant'>;
export interface NotificationProps extends PartialToastProps {
    id: number;
    content: React.ReactNode;
    show: boolean;
}

type Callback = (notifications: NotificationProps[]) => void;


class NotificationService {
    private notifications: NotificationProps[] = [];
    private nextNotificationId = 1;
    private callbacks: Callback[] = [];

    addCallback(callback: Callback) {
        if (!this.callbacks.includes(callback)) {
            this.callbacks.push(callback);
        }
    }

    removeCallback(callback: Callback) {
        const idx = this.callbacks.indexOf(callback);
        if (idx >= 0) {
            this.callbacks.splice(idx, 1);
        }
    }

    showNotification(props: PartialToastProps, content: React.ReactNode) {
        const id = this.nextNotificationId++;
        // set the notification to be not shown initially, then fade it in
        const newNotification = {
            id,
            ...props,
            content,
            show: false,
        };
        this.notifications.push(newNotification);
        this.notifyListeners();
        setTimeout(() => {
            newNotification.show = true;
            this.notifyListeners();
        }, 0);
    }

    // utility method for showing a standard success notification
    showSuccessNotification(content: React.ReactNode, header = 'Success') {
        this.showNotification({
            header,
            variant: ToastVariant.Success,
            icon: IconType.Success,
        }, content);
    }

    // utility method for showing a standard error notification
    showErrorNotification(content: React.ReactNode, header = 'An error occurred') {
        this.showNotification({
            header,
            variant: ToastVariant.Danger,
            icon: IconType.Warning,
        }, content);
    }

    hideNotification(id: number) {
        const notification = this.notifications.find(n => n.id === id);
        if (notification) {
            notification.show = false;
            this.notifyListeners();
            // wait for the notification to fade out before removing it
            setTimeout(() => {
                this.notifications = this.notifications.filter(n => n.id === id);
                this.notifyListeners();
            }, 500);
        }
    }

    private notifyListeners() {
        for (let i = 0; i < this.callbacks.length; i++) {
            try {
                this.callbacks[i](this.notifications);
            } catch (err) {
                Log.reportAndLogError('Callback threw error', err);
            }
        }
    }

}

export const notificationService = new NotificationService();
