import { ExternalResource } from "./ExternalResource"
import { resources } from "./resources"

export const submitLogout = async (externalResource: ExternalResource): Promise<void> => {
    // log the user out
    await externalResource.action(resources.logout, null);
    // call the getAccount API to get new session and CSRF tokens
    // this call fails with a 401 error but returns the cookies we need
    try {
        await externalResource.action(resources.getAccount, null);
    } catch (err) {
    }
};
