import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { TextField } from '../../components/form/fields/TextField';
import { Form } from '../../components/form/forms/Form';
import { Alert, AlertVariant } from '../../components/ui/elements/Alert';
import { ButtonVariant, SubmitButton } from '../../components/ui/elements/Button';
import { LoadingOverlay } from '../../components/ui/elements/LoadingOverlay';
import { WithTestID } from '../../components/ui/elements/WithTestID';
import { ButtonList } from '../../components/ui/layouts/ButtonList';
import { CenterSmallContainerWithSupportBanner } from '../../components/ui/layouts/CenterSmallContainerWithSupportBanner';
import { useExternalResource } from '../../Context/ServiceContext';
import { requestPasswordReset } from '../../services/requestPasswordReset';

interface ResetFormValues {
    email: string;
}
const initialValues: ResetFormValues = { email: '' };

enum PageState {
    Initial = 'Initial',
    Success = 'Success',
}

export const ResetRequest: React.VFC<WithTestID> = ({ testID }) => {

    const [pageState, setPageState] = useState(PageState.Initial);

    return (
        <CenterSmallContainerWithSupportBanner>
            <h1 data-testid={testID ? `${testID}:header` : undefined}>Password Reset</h1>
            {pageState === PageState.Initial && <ResetForm onComplete={() => setPageState(PageState.Success)} testID={testID ? `${testID}:reset-form` : undefined} />}
            {pageState === PageState.Success && <ResetSuccess testID={testID ? `${testID}:success` : undefined} />}
        </CenterSmallContainerWithSupportBanner>
    );

};

interface ResetFormProps extends WithTestID {
    onComplete: () => void;
}
const ResetForm: React.VFC<ResetFormProps> = ({ onComplete, testID }) => {

    const externalResource = useExternalResource();
    const [submitting, setSubmitting] = useState(false);

    const submit = async (values: ResetFormValues) => {
        try {
            setSubmitting(true);
            await requestPasswordReset(externalResource, values.email);
        } catch (err) {
        }
        setSubmitting(false);
        onComplete();
    };

    return (
        <>
            <div className="py-2">
                <Form
                    FormBody={() => (
                        <TextField
                            label="Email address"
                            id="email"
                            placeholder="Your email address"
                            required
                            testID={testID ? `${testID}:email` : undefined}
                        />
                    )}
                    onSubmit={submit}
                    initialValues={initialValues}
                    FormActions={({isSubmitting, values}) => (
                        <ButtonList>
                            <Link to="/login" className="btn btn-outline-primary">Back</Link>
                            <SubmitButton
                                variant={ButtonVariant.PRIMARY}
                                disabled={isSubmitting || !values.email}
                                testID={testID ? `${testID}:submit` : undefined}
                            >Email Password Reset</SubmitButton>
                        </ButtonList>
                    )}
                    testID={testID}
                />
            </div>
            <LoadingOverlay show={submitting} message="Please wait..." />
        </>
    );

};

const ResetSuccess: React.VFC<WithTestID> = ({ testID }) => {
    return (
        <>
            <div className="py-2" data-testid={testID ? `${testID}:msg` : undefined}>
                <Alert variant={AlertVariant.INFO}>
                    Password reset instructions will be sent to your email address if a matching account
                    is found in the system. If you do not receive an email, please check the email address,
                    or contact the system administrator.
                </Alert>
            </div>
            <div className="py-2">
                <ButtonList>
                    <Link to="/login" className="btn btn-outline-primary">Back</Link>
                </ButtonList>
            </div>
        </>
    );
};

