import React from 'react';
import { useAuthenticatedUser } from '../../../Context/AuthenticationContext';
import { getAppTypesForUser } from '../../../domain/Account';
import { useAppTypeWithFallback } from '../../../hooks/useAppTypeWithFallback';
import { AppTypeSelectorLayout } from '../layouts/AppTypeSelectorLayout';

export const AppTypeSelector: React.VFC = () => {
    const user = useAuthenticatedUser();
    const appType = useAppTypeWithFallback();
    const appTypes = getAppTypesForUser(user);

    if (!appType) return null;

    return <AppTypeSelectorLayout currentOption={appType.appType} options={appTypes} />;
};
