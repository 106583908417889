import { useContext } from 'react';
import { AuthenticationContext } from '../Context/AuthenticationContext';

import { ServiceContext } from '../Context/ServiceContext';
import { getAppTypesForUser } from '../domain/Account';
import { AppType, GetAppType } from '../domain/AppType';

// Get the current appType from the URL.
// If not available (e.g. if the user navigates to an invalid url with no appType - `/#/app/foo`),
// then return the first available appType for the logged-in user.

// If the user is not logged in, or has no available appTypes, returns undefined.
export const useAppTypeWithFallback = (): AppType|undefined => {
    const { getAppType } = useContext(ServiceContext);
    const { auth } = useContext(AuthenticationContext);
    const { userAccount } = auth;

    try {
        return getAppType();
    } catch (err) {
        // Could not get appType from current URL
        if (userAccount) {
            const appTypes = getAppTypesForUser(userAccount);
            if (appTypes.length > 0) {
                return GetAppType(appTypes[0]);
            }
        }

        // No app types available
        return undefined;
    }

};
