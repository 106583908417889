import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export interface AppTypeSelectorLayoutProps {
    options: string[];
    currentOption: string;
}

export const AppTypeSelectorLayout: React.VFC<AppTypeSelectorLayoutProps> = ({ options, currentOption }) => {

    if (options.length > 1) {
        return (
            <Nav>
                <NavDropdown title={currentOption} active>
                    {options.map((opt) => (
                        <NavDropdown.Item key={opt} as={Link} to={`/app/${opt}`}>{opt}</NavDropdown.Item>
                    ))}
                </NavDropdown>
            </Nav>
        );
    }

    return (
        <Navbar.Text>
            {currentOption}
        </Navbar.Text>
    );
};
